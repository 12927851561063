<template>
  <v-main>
      <div
          class="cookie-notification"
          v-if="cookieShow"
      >
          <p>
              Этот сайт использует cookie и другие технологии для сбора информации технического характера. Подробнее о
              <router-link :to="{name: 'Cookies'}" target="_blank">
                  файлах cookie
              </router-link>
          </p>
          <v-btn
              color="gray"
              class="cookie-accept"
              @click="cookieAccept"
          >
              Хорошо
          </v-btn>
      </div>
      <div class="wrapper">
        <section class="section-1">
          <img class="bg-main-top-l" src="/img/manelixir/soty-w.png" alt="">
          <img class="bg-main-blur" src="/img/manelixir/blur.png" alt="">
          <h1 class="h1-main">Инновационный продукт для тех,<br> кто стремится быть лучшим во всем</h1>
          <h2 class="h2-title-main">Что будет, если собрать все то, что нужно для мужчины в одном флаконе?</h2>
          <div class="block-img-main">
            <div class="block-box">
              <img class="bg-main-box" src="/img/manelixir/box-man.png" alt="">
              <!-- <img class="bg-main-kapli" src="/img/manelixir/kapli.png" alt=""> -->
            </div>
            <img class="bg-main-btm-r" src="/img/manelixir/soty2.png" alt="">
          </div>
          <h3 class="h3-title-wb">Наш уникальный продукт 5 в 1 формула для мужчин</h3>
          <btn class="btn-more" @click="scrollToMore()"><img class="img-more" src="/img/manelixir/btn-more.png"></btn>
        </section>
              
        
        <section class="section-2">
          <img class="bg-main-wave-1" src="/img/manelixir/wave-bg.png" alt="">
          <v-row>
            <v-col class="col-box" cols="4">
              <img class="boxer-img" src="/img/manelixir/boxer.png" alt="">
            </v-col>
            <!-- <img class="boxer-img-mobile" src="/img/manelixir/boxer.png" alt=""> -->
            <v-col class="sec-2-txt" cols="8">
              <h2 class="h2-title-wb top-h3-sec-2">Ты все решаешь сам - ты мужчина</h2>
              <p class="p-man">Для кого:</p>
              <ul class="ul-man">
                <li>Ты постоянно бросаешь новые вызовы</li>
                <li>Ты готов действовать здесь и сейчас</li>
                <li>Ты хочешь расширять границы возможностей</li>
                <li>Ты чувствуешь себя комфортно в своем теле</li>
              </ul>
              <h3 class="h3-title-wb btm-h3-sec-2">Продли свою мужественность</h3>
            </v-col>
          </v-row>
        </section>

        <section class="section-3">
          <h2 class="h2-title-wb">Позволь своему телу и мозгу<br class="br-mob"> работать на полную мощность</h2>
          <div class="video-block">
            <div style="width: 100%; max-width: 800px; height: auto; margin: 0 auto">
              <div class="thumb-wrap">
                <iframe width="720" height="405" src="https://rutube.ru/play/embed/a598a133265e26ac3f3395716bab194e/" frameBorder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
              </div>
            </div>
          </div>
          
          <img class="bg-3-btm-l" src="/img/manelixir/soty.png" alt="">
          <h3 class="h3-title-wb">1 флакон - море возможностей</h3>
        </section>

        <section class="section-4">
          <h2 class="h2-title-wb">Объединяет в себе новейшие достижения науки<br> с лучшими природными компонентами</h2>
          <v-row>
            <v-col class="col-black-man" cols="4">
              <img class="man-black-img" src="/img/manelixir/man-black.png" alt="">
            </v-col>
            <!-- <img class="man-black-mobile" src="/img/manelixir/man-black.png" alt=""> -->
            <v-col class="sec-3-txt" cols="8">          
              <h3 class="h3-blue">Что ты получишь:</h3>
              <ul class="ul-man-k">
                <li><img src="/img/manelixir/kaplya.png" class="kap-li">Больше энергии</li>
                <li><img src="/img/manelixir/kaplya.png" class="kap-li">Здоровую кожу</li>
                <li><img src="/img/manelixir/kaplya.png" class="kap-li">Густые волосы</li>
                <li><img src="/img/manelixir/kaplya.png" class="kap-li">Крепкие мышцы</li>
                <li><img src="/img/manelixir/kaplya.png" class="kap-li">Здоровую потенцию</li>
                <li><img src="/img/manelixir/kaplya.png" class="kap-li">Уверенность в завтрашнем дне</li>
                <li><img src="/img/manelixir/kaplya.png" class="kap-li">Концентрацию на главных задачах</li>
                <li><img src="/img/manelixir/kaplya.png" class="kap-li">Экзотический незабываемый вкус</li>
              </ul>
            </v-col>
          </v-row>
          <img class="bg-wave-sec-4" src="/img/manelixir/wave-bg-2.png" alt="">
        </section>
        
        <section class="section-5 pb-10" id="sostav">
          <div class="sostav-block">
            <v-row class="mb-3">
              <v-col class="act-col" cols="12" sm="5">
                <h3 class="h3-title-wb h3-sostav">Активный комплекс</h3>
                <p class="sos-p">Активирует строительные процессы и обеспечивает всеми необходимыми веществами</p>
                <p class="p-18"><strong>Результат:</strong></p>
                <ul class="ul-col">
                  <li>Эффективное наращивание мышц</li>
                  <li>Стабильная выработка гормонов</li>
                  <li>Ежедневное восстановление</li>
                </ul>
                <ul class="ul-col mt-5">
                  <li>Гидролизат коллагена</li>
                  <li>10 витаминов - 100% СП</li>
                  <li>Цитрат магния - 50% СП</li>
                  <li>Цинк - 100% СП</li>
                  <li>Селен - 82% СП</li>
                  <li>Медь и марганец - 25% СП</li>
                </ul>
              </v-col>
              <v-col class="big-k-col" cols="12" sm="2">
                <img class="kaplya-center" src="/img/manelixir/kaplya-big.png">
              </v-col>
              <v-col class="prev-col" cols="12" sm="5">
                <h3 class="h3-title-wb h3-sostav">Превентивный комплекс</h3>
                <p class="sos-p">Защита от негативных факторов окружающей среды и стресса, устранение мужских факторов риска сердечно-сосудистой системы, антивозрастной эффект</p>
                <p class="p-18"><strong>Результат:</strong></p>
                <ul class="ul-col">
                  <li>Защита сосудов</li>
                  <li>Защита кожи от старения</li>
                  <li>Сохранение структуры и цвета волос</li>
                </ul>
                <ul class="ul-col mt-5">
                  <li>Полифенолы, кофеин из зеленого чая</li>
                  <li>Антиоксидантные витамины (A, C, E)</li>
                  <li>L-аргинин, цистеин, пролин</li>
                  <li>Пиперин (BioPerine - экстракт черного перца)</li>
                  <li>Экстракт черной смородины</li>
                  <li>Экстракт красного апельсина</li>
                </ul>
              </v-col>
            </v-row>

            <h3 class="h3-title-wb h3-sostav">Состав:</h3>
            <v-row>
              <v-col class="sostav-left" cols="12" sm="6">
                <ul class="sostav-ul-left">
                  <li @click="dialog_collagen = true"><img src="/img/manelixir/kaplya.png" class="kap-sostav">Гидролизат коллагена 2,5 г</li>
                  <li @click="dialog_cestein = true"><img src="/img/manelixir/kaplya.png" class="kap-sostav">L-цестеин 100 мг</li>
                  <li @click="dialog_selen_zink = true"><img src="/img/manelixir/kaplya.png" class="kap-sostav">Селен 45 мкг</li>
                  <li @click="dialog_selen_zink = true"><img src="/img/manelixir/kaplya.png" class="kap-sostav">Цинк 10 мг</li>
                  <li @click="dialog_arginin = true"><img src="/img/manelixir/kaplya.png" class="kap-sostav">L-аргинин 690 мг</li>
                  <li @click="dialog_prolin = true"><img src="/img/manelixir/kaplya.png" class="kap-sostav">L-пролин 432 мг</li>
                  <li @click="dialog_cuprum = true"><img src="/img/manelixir/kaplya.png" class="kap-sostav">Медь 270 мкг</li>
                  <li @click="dialog_vitB = true"><img src="/img/manelixir/kaplya.png" class="kap-sostav">Витамины В1, В2, В3, В6, В7, В12</li>
                  <li @click="dialog_vitA = true"><img src="/img/manelixir/kaplya.png" class="kap-sostav">Витамин А 600 мкг</li>
                  <li @click="dialog_vitD = true"><img src="/img/manelixir/kaplya.png" class="kap-sostav">Витамин Д3 5 мкг</li>
                  <li @click="dialog_vitC = true"><img src="/img/manelixir/kaplya.png" class="kap-sostav">Витамин С 80 мг</li>
                  <li @click="dialog_magniy = true"><img src="/img/manelixir/kaplya.png" class="kap-sostav">Магний 187,5 мг</li>
                  <li @click="dialog_marganec = true"><img src="/img/manelixir/kaplya.png" class="kap-sostav">Марганец 0,5 мг</li>
                </ul>
              </v-col>
              <v-col class="sostav-right" cols="12" sm="6">
                <ul class="sostav-ul-right">
                  <li @click="dialog_smorodina = true" class="yagoda"><img src="/img/manelixir/yagoda.png"><span>Концентрат сока черной смородины 200 мг</span></li>
                  <li @click="dialog_apelsin = true" class="apelsin"><img src="/img/manelixir/orange.png"><span>Экстракт плодов красного апельсина 7,8 мг</span></li>
                  <li @click="dialog_kofein = true" class="poroshok"><img src="/img/manelixir/poroshok.png"><span>Кофеин из экстракта листьев зеленого чая 7,5 мг</span></li>
                  <li @click="dialog_paper = true" class="paper"><img src="/img/manelixir/paper.png"><span>Экстракт плодов черного перца 1 мг</span></li>
                </ul>
                <img class="bg-5-btm-r" src="/img/manelixir/soty2.png" alt="">
              </v-col>
            </v-row>
            <div style="text-align: center;" class="mt-10">
              <button class="btn-buy" @click="scrollToForm()"><img class="img-buy" src="/img/manelixir/btn-buy.png"></button>
            </div>
          </div>
        </section>

        <section class="section-7-1">
          <h2 class="h2-title">Рекомендации экспертов, выбор настоящих мужчин</h2>
          <div class="video-block">
            <div style="width: 100%; max-width: 800px; height: auto; margin: 0 auto">
              <div class="thumb-wrap">
                <iframe width="720" height="405" src="https://rutube.ru/play/embed/76f1076017891d466e004568a3e8a5d0/" frameBorder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
              </div>
            </div>
          </div>
          <h4 class="h4-title">З. Г-М. Берикханов</h4>
          <p class="req-p">К.М.Н., доцент кафедры госпитальной хирургии 2 ИКМ им. Н.В. Склифосовского и Сеченовского Университета, врач-методист отдела анализа кадровой политики и мониторинга организации медицинской помощи НМИЦ по профилю "пульмонология", врач-торакальный хирург.</p>
          <img class="bg-7-wave-r" src="/img/manelixir/wave-right.png" alt="">
        </section>

        <section class="section-7-2">
          <div class="video-block">
            <div style="width: 100%; max-width: 800px; height: auto; margin: 0 auto">
              <div class="thumb-wrap">
                <iframe width="720" height="405" src="https://rutube.ru/play/embed/2ff55602fe5114ded9dd8b3bb7f3e686/" frameBorder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
              </div>
            </div>
          </div>
          <h4 class="h4-title">В.А. Дадали</h4>
          <p class="req-p">Д.Х.Н., профессор СЗГМУ им И.И. Мечникова. Доктор философии по натуральной медицине и сертифицированный консультант по нутрициологии Университета натуральной медицины, Калифорния, США.</p>
          <img class="bg-7-wave-l" src="/img/manelixir/wave-left.png" alt="">
        </section>

        <section class="section-8">
          <h2 class="h2-title-wb lr-proizv">Производитель - LR Health & Beauty</h2>
          <h3 class="h3-title">Немецкое качество и забота о каждом клиенте</h3>
          <div class="video-block"><div style="width: 100%; max-width: 800px; height: auto; margin: 0 auto">
            <div class="thumb-wrap">
              <iframe width="720" height="405" src="https://rutube.ru/play/embed/b2c130bac996ff5cf6d938e3d7f53dee/" frameBorder="0" allow="clipboard-write; autoplay" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
            </div>
          </div>
          </div>
          <ul class="ul-lr">
            <li>40 лет успеха в сфере здоровья и красоты</li>
            <li>лидер по продаже БАДов на рынке Европы</li>
            <li>инновационные технологии и Эко производство</li>
            <li>бесплатная доставка до дома в более чем 30 странах</li>
            <li>свой научный совет и уникальные запатентованные формулы</li>
            <li>все этапы производства и упаковки только в Германии</li>
            <li>сотни тысяч счастливых клиентов</li>
          </ul>
          <div class="cert-block">
            <v-row>
              <v-col cols="3" class="cert-col cert-img"><img class="img-col dermatest" src="/img/manelixir/dermatest.png" alt=""></v-col>
              <v-col cols="9" class="cert-col">Безопасность продуктов Алоэ Вера LR для кожи подтверждена известными независимыми институтами, такими как НИИ Dermatest и Derma Consult и одобрена дерматологами.</v-col>
            </v-row>
            <v-row>
              <v-col cols="3" class="cert-col cert-img"><img class="img-col aisc" src="/img/manelixir/aloe-cert.png" alt=""></v-col>
              <v-col cols="9" class="cert-col">Знак качества Международного научного совета по Алоэ (IASC), что подтверждает бережную обработку сырья и высокое содержание геля Алоэ Вера без алоина.</v-col>
            </v-row>
            <v-row>
              <v-col cols="3" class="cert-col cert-img"><img class="img-col frezenius" src="/img/manelixir/fresenius.png" alt=""></v-col>
              <v-col cols="9" class="cert-col">Сертификат Института Фрезениуса (SGS INSTITUT FRESENIUS), что означает независимую регулярную проверку производства и качество продукции на всех этапах: от сбора сырья, до характеристик готового продукта.</v-col>
            </v-row>
          </div>
          <h3 class="h3-title-wb lr-more">LR - больше качества в твоей жизни</h3>
          <img class="bg-wave-btm" src="/img/manelixir/wave-bg-2.png" alt="">
        </section>
        
        <section class="section-9">
          <h2 class="h2-title-wb lr-otziv">Надежный компаньон и верный товарищ твоего здоровья</h2>
          <div>
            <v-carousel
              class="me-carousel"
              cycle
              hide-delimiter-background
              show-arrows-on-hover
              hide-delimiters
            >
              <v-carousel-item
                  v-for="(slide, i) in slides"
                  :key="i"
              >
                <v-sheet
                  height="100%"
                >
                  <v-row
                    class="fill-height"
                    align="center"
                    justify="center"
                  >
                    <div class="text-h2">
                      <p style="width: 100%; max-width: 600px; height: auto">
                          <img class="img-corusel" style="width: 100%;" :src=slide.src alt=""/>
                      </p>
                    </div>
                  </v-row>
                </v-sheet>
              </v-carousel-item>
            </v-carousel>
          </div>
          <ul class="ul-otzivi">
            <li><img src="/img/manelixir/check-w.png" class="check-li">всего один флакон в день</li>
            <li><img src="/img/manelixir/check-w.png" class="check-li">удобно брать с собой</li>
            <li><img src="/img/manelixir/check-w.png" class="check-li">запатентованная уникальная формула</li>
          </ul>
          <h3 class="h3-title-wb man-alfa">Проверь секрет альфа самцов на себе</h3>
        </section>

        <section v-if="userData.id != 127" class="section-10" id="me-form">
            <v-container class="text-center px-0">
                <h2 class="h2-form">Оставь заявку и получи профессиональную бесплатную <br class="br-mobile"> консультацию по применению мужской капли 5 в 1</h2>
                <h3 class="h3-form">Ежедневная поддержка всего, что важно для мужчин</h3>
                <v-responsive
                    class="mx-auto"
                    max-width="600px"
                >
                    <v-text-field
                      class="form-name"
                        label="Ваше имя"
                        prepend-icon="mdi-face-man"
                        type="text"
                        v-model.trim="newProspect.name"
                        :error-messages="nameErrors"
                        @input="$v.newProspect.name.$touch()"
                        @blur="$v.newProspect.name.$touch()"
                    />
                    <v-text-field
                        class="input-phone"
                        label="Телефон"
                        prepend-icon="mdi-phone"
                        type="text"
                        v-model.trim="newProspect.phone"
                        :error-messages="phoneErrors"
                        @input="$v.newProspect.phone.$touch()"
                        @blur="$v.newProspect.phone.$touch()"
                    />
                    <div class="invalid-feedback" v-if="errors.phone">
                        {{ errors.phone[0] }}
                    </div>

                    <p style="text-align: center">
                        <btn
                            class="mr-0 btn-send"
                            @click="addProspectMe()"
                        >
                            <img class="img-send" src="/img/manelixir/btn-send.png">
                        </btn>
                    </p>
                    <p class="privacy-text me-privacy">
                        Оставляя заявку, вы даете свое
                        <router-link :to="{name: 'Personal'}" target="_blank">Согласие на обработку персональных данных</router-link>
                        и принимаете
                        <router-link :to="{name: 'Policy'}" target="_blank">Политику конфиденциальности</router-link>.
                    </p>
                </v-responsive>
                <h3 class="h3-title-wb man-end">Дай своему телу то, что нужно и оно покажет тебе на что способно</h3>
            </v-container>
            <img class="bg-wave-form" src="/img/manelixir/wave-bg-2.png" alt="">
        </section>
        <v-footer
            class="justify-center me-footer"
            height="100"
        >
            <div class="title font-weight-light text-center">
                &copy; {{ (new Date()).getFullYear() }} — newlvl.net — независимый партнер LR Health & Beauty
            </div>
        </v-footer>

        <v-dialog
            v-model="dialog_prospect"
            width="500"
            class="dialog-prospect"
        >
            <v-card>
                <v-card-title class="headline lighten-2 pb-5" style="background-color: #005C67; color: white">
                    Заявка принята
                </v-card-title>

                <v-card-text>
                    Ваш консультант свяжется с вами в ближайшее время
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="#005C67"
                        text
                        @click="dialog_prospect = false"
                    >
                        Хорошо
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-model="dialog_collagen"
            width="500"
            class="dialog-sostav"
        >
            <v-card>
              <btn class="close-btn" @click="dialog_collagen = false">X</btn>
              <img class="img-sostav" src="/img/manelixir/sostav/kollagen.jpg">
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialog_cestein"
            width="500"
            class="dialog-sostav"
        >
            <v-card>
              <btn class="close-btn" @click="dialog_cestein = false">X</btn>
              <img class="img-sostav" src="/img/manelixir/sostav/l-cestein.jpg">
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialog_selen_zink"
            width="500"
            class="dialog-sostav"
        >
            <v-card>
              <btn class="close-btn" @click="dialog_selen_zink = false">X</btn>
              <img class="img-sostav" src="/img/manelixir/sostav/selen-zink.jpg">
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialog_arginin"
            width="500"
            class="dialog-sostav"
        >
            <v-card>
              <btn class="close-btn" @click="dialog_arginin = false">X</btn>
              <img class="img-sostav" src="/img/manelixir/sostav/l-arginin.jpg">
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialog_prolin"
            width="500"
            class="dialog-sostav"
        >
            <v-card>
              <btn class="close-btn" @click="dialog_prolin = false">X</btn>
              <img class="img-sostav" src="/img/manelixir/sostav/l-prolin.jpg">
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialog_cuprum"
            width="500"
            class="dialog-sostav"
        >
            <v-card>
              <btn class="close-btn" @click="dialog_cuprum = false">X</btn>
              <img class="img-sostav" src="/img/manelixir/sostav/cuprum.jpg">
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialog_vitB"
            width="500"
            class="dialog-sostav"
        >
            <v-card>
              <btn class="close-btn" @click="dialog_vitB = false">X</btn>
              <img class="img-sostav" src="/img/manelixir/sostav/vit-B.jpg">
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialog_vitA"
            width="500"
            class="dialog-sostav"
        >
            <v-card>
              <btn class="close-btn" @click="dialog_vitA = false">X</btn>
              <img class="img-sostav" src="/img/manelixir/sostav/vit-A.jpg">
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialog_vitD"
            width="500"
            class="dialog-sostav"
        >
            <v-card>
              <btn class="close-btn" @click="dialog_vitD = false">X</btn>
              <img class="img-sostav" src="/img/manelixir/sostav/vit-D.jpg">
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialog_apelsin"
            width="500"
            class="dialog-sostav"
        >
            <v-card>
              <btn class="close-btn" @click="dialog_apelsin = false">X</btn>
              <img class="img-sostav" src="/img/manelixir/sostav/apelsin.jpg">
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialog_vitC"
            width="500"
            class="dialog-sostav"
        >
            <v-card>
              <btn class="close-btn" @click="dialog_vitC = false">X</btn>
              <img class="img-sostav" src="/img/manelixir/sostav/vit-C.jpg">
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialog_magniy"
            width="500"
            class="dialog-sostav"
        >
            <v-card>
              <btn class="close-btn" @click="dialog_magniy = false">X</btn>
              <img class="img-sostav" src="/img/manelixir/sostav/magniy.jpg">
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialog_marganec"
            width="500"
            class="dialog-sostav"
        >
            <v-card>
              <btn class="close-btn" @click="dialog_marganec = false">X</btn>
              <img class="img-sostav" src="/img/manelixir/sostav/marganec.jpg">
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialog_smorodina"
            width="500"
            class="dialog-sostav"
        >
            <v-card>
              <btn class="close-btn" @click="dialog_smorodina = false">X</btn>
              <img class="img-sostav" src="/img/manelixir/sostav/smorodina.jpg">
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialog_kofein"
            width="500"
            class="dialog-sostav"
        >
            <v-card>
              <btn class="close-btn" @click="dialog_kofein = false">X</btn>
              <img class="img-sostav" src="/img/manelixir/sostav/kofein.jpg">
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialog_paper"
            width="500"
            class="dialog-sostav"
        >
            <v-card>
              <btn class="close-btn" @click="dialog_paper = false">X</btn>
              <img class="img-sostav" src="/img/manelixir/sostav/paper.jpg">
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialog_prolin"
            width="500"
            class="dialog-sostav"
        >
            <v-card>
              <btn class="close-btn" @click="dialog_prolin = false">X</btn>
              <img class="img-sostav" src="/img/manelixir/sostav/l-prolin.jpg">
            </v-card>
        </v-dialog>
    </div>
  </v-main>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {maxLength, minLength, numeric, required} from "vuelidate/lib/validators";

export default {
  name: "ManElixir",
  data: () => ({
      newProspect: {
          phone: '',
          partner: '',
          instrument: 'Мужской Эликсир',
          step: 'Новый'
      },
      slides: [
        {
            src: '/img/manelixir/otzivi/1.png',
        },
        {
            src: '/img/manelixir/otzivi/2.png',
        },
        {
            src: '/img/manelixir/otzivi/3.png',
        },
        {
            src: '/img/manelixir/otzivi/4.png',
        },
        {
            src: '/img/manelixir/otzivi/5.png',
        },
        {
            src: '/img/manelixir/otzivi/6.png',
        },
        {
            src: '/img/manelixir/otzivi/7.png',
        },
        {
            src: '/img/manelixir/otzivi/8.png',
        },
        {
            src: '/img/manelixir/otzivi/9.png',
        },
        {
            src: '/img/manelixir/otzivi/10.png',
        },
      ],
      errors: {},
      dialog_collagen: false,
      dialog_cestein: false,
      dialog_selen_zink: false,
      dialog_arginin: false,
      dialog_prolin: false,
      dialog_cuprum: false,
      dialog_vitB: false,
      dialog_vitA: false,
      dialog_vitC: false,
      dialog_vitD: false,
      dialog_magniy: false,
      dialog_marganec: false,
      dialog_smorodina: false,
      dialog_apelsin: false,
      dialog_kofein: false,
      dialog_paper: false,
      dialog_prospect: false,
      loading: false,
      cookieShow: true,
  }),
  metaInfo: {
      title: 'Мужской Эликсир 5 в 1',
      meta: [
          { vmid: 'description', property: 'description', content: 'Инновационный продукт - Мужской Эликсир 5 в 1. Больше энергии, здоровая кожа, здоровая потенция, густые волосы и крепкие мышцы' },
          { vmid: 'og:title', property: 'og:title', content: 'Мужской Эликсир 5 в 1' },
          { vmid: 'og:description', property: 'og:description', content: 'Инновационный продукт - Мужской Эликсир 5 в 1. Больше энергии, здоровая кожа, здоровая потенция, густые волосы и крепкие мышцы' },
      ],
  },
  created() {
      if (!this.$route.query.partner) {
          if (this.$cookies.isKey('newlvl_partner')) {
              const partnerNum = this.$cookies.get('newlvl_partner')
              this.$router.push ({name: 'ManElixir', query: { partner: partnerNum }})
          }
      } else {
          this.$cookies.set('newlvl_partner', this.$route.query.partner, '6m')
      }

      if (this.$cookies.isKey('newlvl_cookie')) {
          this.cookieShow = false
      }
  },
  computed: {
      ...mapGetters({
          userData: 'user/getUser'
      }),
      dataUser() {
          return this.userData
      },
      nameErrors() {
          const errors = []
          if (!this.$v.newProspect.name.$dirty) return errors
          !this.$v.newProspect.name.minLength && errors.push('Минимум 2 буквы')
          !this.$v.newProspect.name.maxLength && errors.push('Максимум 30 букв')
          !this.$v.newProspect.name.required && errors.push('Обязательно для заполнения')
          return errors
      },
      phoneErrors() {
          const errors = []
          if (!this.$v.newProspect.phone.$dirty) return errors
          !this.$v.newProspect.phone.numeric && errors.push('Только цифры')
          !this.$v.newProspect.phone.minLength && errors.push('Минимум 5 цифр')
          !this.$v.newProspect.phone.maxLength && errors.push('Максимум 30 цифр')
          !this.$v.newProspect.phone.required && errors.push('Обязательно для заполнения')
          return errors
      }
  },
  methods: {
      ...mapActions({
          addNotification: 'application/addNotification',
          showUser: 'user/showUserPage',
          createProspect: 'user/addClient'
      }),
      cookieAccept() {
          this.cookieShow = false
          this.$cookies.set('newlvl_cookie', 'accept', '12m')
      },
      scrollToMore () {
          let scrollBottom = document.getElementById("sostav")
          scrollBottom.scrollIntoView({ behavior: "smooth" })
      },
      scrollToForm () {
          let scrollBottom = document.getElementById("me-form")
          scrollBottom.scrollIntoView({ behavior: "smooth" })
      },
      addProspectMe() {
          this.$v.$touch()
          if (!this.$v.$invalid) {
              this.loading = true
              this.createProspect(this.newProspect)
                  .then((response) => {
                      if (response.data && response.data.success) {
                          this.errors = {}
                          this.loading = false
                          this.dialog_prospect = true
                      }
                  })
                  .catch((error) => {
                      switch (error.response.status) {
                          case 422:
                              this.loading = false
                              this.addNotification({
                                  show: true,
                                  text: error.response.data.message,
                                  color: 'error'
                              })
                              this.errors = error.response.data.errors
                              break
                          case 500:
                              this.loading = false
                              this.addNotification({
                                  show: true,
                                  text: error.response.data.message,
                                  color: 'error'
                              })
                              this.errors = error.response.data.errors
                              break
                          default:
                              this.loading = false
                              this.addNotification({
                                  show: true,
                                  text: error.response.data.message,
                                  color: 'error'
                              })
                              this.errors = error.response.data.errors
                              break
                      }
                  })
          } else {
              this.addNotification({
                  show: true,
                  text: 'Ошибка валидации',
              })
          }
      },
  },
  mounted () {
    this.showUser({user: this.$route.query.partner, inst: this.$route.query.inst})
    this.newProspect.partner = this.$route.query.partner
  },
  validations: {
      newProspect: {
        name: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(30)
        },
        phone: {
          required,
          numeric,
          minLength: minLength(5),
          maxLength: maxLength(30)
        }
      }
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: "Evolventa";
  src: url('/fonts/Evolventa-Regular.otf');
}
@font-face {
  font-family: "Evolventa-Bold";
  src: url('/fonts/Evolventa-Bold.otf');
}
.wrapper {
  background-color: #000;
  font-size: 16px;
  color: #fff;
  font-family: "Evolventa";
  overflow: hidden;
}
.section-1 {
  // background-image: url(/img/manelixir/bg-1.jpg);
  // background-position: center center;
  // background-repeat: no-repeat;
  // background-attachment: fixed;
  // background-size: cover;
  background: linear-gradient(to top, #000, #017F8E);
  // height: 100dvh;
  position: relative;
  text-align: center;
}
.bg-main-top-l {
  position: absolute;
  top: 10px;
  left: 10px;
  width: calc(100px + (280 - 150) * ((100vw - 320px) / (1280 - 320)));
  z-index: 500;
}
.bg-main-blur {
  position: absolute;
  top: 0;
  left: 0;
  // width: calc(150px + (280 - 150) * ((100vw - 320px) / (1280 - 320)));
  z-index: 500;
  opacity: 0.6;
}
.h1-main {
  text-align: center;
  font-size: calc(12px + (40 - 12) * ((100vw - 320px) / (1280 - 320)));
  text-transform: uppercase;
  line-height: 120%;
  padding-top: calc(40px + (40 - 20) * ((100vw - 320px) / (1280 - 320)));
  padding-bottom: calc(20px + (30 - 20) * ((100vw - 320px) / (1280 - 320)));
  // font-family: "Evolventa-Bold";
  letter-spacing: 1px;
  position: relative;
  z-index: 1000;
}
.h2-title-main {
  text-align: center;
  font-size: calc(8px + (24 - 8) * ((100vw - 320px) / (1280 - 320)));
  text-transform: uppercase;
  line-height: 110%;
  font-weight: normal;
}
.h2-title {
  text-align: center;
  font-size: calc(10px + (28 - 10) * ((100vw - 320px) / (1280 - 320)));
  text-transform: uppercase;
  line-height: 150%;
  font-weight: normal;
  // letter-spacing: 1.5px;
  padding-top: calc(0px + (30 - 20) * ((100vw - 320px) / (1280 - 320)));
  padding-bottom: calc(20px + (30 - 20) * ((100vw - 320px) / (1280 - 320)));
  // text-decoration: underline;
}
.block-img-main {
  position: relative;
}
.block-box {
  position: relative;
  width: calc(250px + (500 - 250) * ((100vw - 320px) / (1280 - 320)));
  margin: 0 auto;
  padding-top: calc(20px + (30 - 20) * ((100vw - 320px) / (1280 - 320)));
  padding-bottom: calc(10px + (20 - 20) * ((100vw - 320px) / (1280 - 320)));
  z-index: 1000;
}
.bg-main-box {
  width: 100%;
}
// .bg-main-kapli {
//   width: calc(60px + (90 - 20) * ((100vw - 320px) / (1280 - 320)));
//   position: absolute;
//   right: calc(-60px + (-120 - 20) * ((100vw - 320px) / (1280 - 320)));
//   bottom: 0;
//   z-index: 500;
// }
.bg-main-btm-r {
  z-index: 500;
  width: calc(150px + (250 - 20) * ((100vw - 320px) / (1280 - 320)));
  position: absolute;
  right: 5%;
  bottom: 0;
  opacity: 0.8;
}
.h2-title-wb {
  font-size: calc(9px + (28 - 9) * ((100vw - 320px) / (1280 - 320)));
  text-transform: uppercase;
  line-height: 150%;
  // font-weight: normal;
  letter-spacing: 1px;
  padding-top: calc(20px + (40 - 20) * ((100vw - 320px) / (1280 - 320)));
  padding-bottom: calc(20px + (30 - 20) * ((100vw - 320px) / (1280 - 320)));
  text-shadow: 1px 1px 2px #00e4ff, 0 0 1em #00e7ff, 0 0 0.2em #00c3ff;
  z-index: 500;
  position: relative;
  // text-decoration: underline;
  text-align: center;
  font-weight: normal;
}
.btn-more, .btn-buy, .btn-send {
  cursor: pointer;
}
.img-more, .img-buy {
  width: calc(200px + (250 - 20) * ((100vw - 320px) / (1280 - 320)));
  z-index: 500;
  position: relative;
}
.img-send {
  width: calc(180px + (200 - 20) * ((100vw - 320px) / (1280 - 320)));
  z-index: 500;
  position: relative;
}
.section-2 {
  position: relative;
  padding-bottom: 20px;
}
.boxer-img {
  width: 100%;
}
// .boxer-img-mobile, .man-black-mobile {
//   display: none;
// }
.bg-main-wave-1 {
  position: absolute;
  width: 160%;
  top: -50%;
  left: -20%;
  z-index: 100;
  opacity: 0.6;
}
.h3-title-wb {
  font-size: calc(10px + (24 - 10) * ((100vw - 320px) / (1280 - 320)));
  text-align: center;
  text-transform: uppercase;
  line-height: 150%;
  font-weight: normal;
  letter-spacing: 1px;
  padding-top: calc(20px + (30 - 20) * ((100vw - 320px) / (1280 - 320)));
  padding-bottom: calc(20px + (30 - 20) * ((100vw - 320px) / (1280 - 320)));
  text-shadow: 1px 1px 2px #00e4ff, 0 0 1em #00e7ff, 0 0 0.2em #00c3ff;
  z-index: 500;
  position: relative;
}
.h3-title {
  font-size: calc(10px + (24 - 10) * ((100vw - 320px) / (1280 - 320)));
  text-align: center;
  text-transform: uppercase;
  line-height: 150%;
  font-weight: normal;
  letter-spacing: 0.5px;
  // padding-top: calc(20px + (20 - 20) * ((100vw - 320px) / (1280 - 320)));
  padding-bottom: calc(15px + (30 - 20) * ((100vw - 320px) / (1280 - 320)));
  z-index: 500;
  position: relative;
}
.h2-title-wb.top-h3-sec-2 {
  padding-bottom: calc(40px + (60 - 20) * ((100vw - 320px) / (1280 - 320)));
  text-align: left;
}
.h3-title-wb.btm-h3-sec-2 {
  padding-top: calc(40px + (60 - 20) * ((100vw - 320px) / (1280 - 320)));
  text-align: left;
}
.sec-2-txt {
  padding-top: calc(15px + (100 - 20) * ((100vw - 320px) / (1280 - 320)));
  padding-left: calc(0px + (70 - 20) * ((100vw - 320px) / (1280 - 320)));
  position: relative;
  z-index: 300;
}
.p-man {
  font-size: calc(10px + (20 - 10) * ((100vw - 320px) / (1280 - 320)));
  text-transform: uppercase;
  line-height: 110%;
  letter-spacing: 1px;
  font-weight: bold;
  padding-bottom: calc(10px + (30 - 20) * ((100vw - 320px) / (1280 - 320)));
}
.ul-man {
  font-size: calc(7px + (20 - 8) * ((100vw - 320px) / (1280 - 320)));
  text-transform: uppercase;
  line-height: 120%;
  letter-spacing: 1px;
}
.ul-man li {
  padding-bottom: 10px;
  margin-left: 30px;
}
.section-3 {
  background: linear-gradient(#000, #017F8E, #000);
  position: relative;
  z-index: 500;
}
.video-block {
  position: relative;
  z-index: 1000;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  // position: relative;
  // padding-bottom: 56.25%;
  // height: 0;
  // overflow: hidden;
}
// .video-block  iframe {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   border-width: 0;
//   outline-width: 0;
// }
.bg-3-btm-l {
  position: absolute;
  left: 5%;
  bottom: 0;
  width: calc(150px + (350 - 150) * ((100vw - 320px) / (1280 - 320)));
}
.section-4 {
  position: relative;
}
.h3-blue {
  font-size: calc(12px + (22 - 12) * ((100vw - 320px) / (1280 - 320)));
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: normal;
  padding-bottom: 40px;
  color: #00e7ff;
}
.sec-3-txt {
  padding-top: calc(20px + (30 - 20) * ((100vw - 320px) / (1280 - 320)));
  padding-left: calc(0px + (70 - 20) * ((100vw - 320px) / (1280 - 320)));
}
.col-black-man {
  position: relative;
}
.br-mob {
  display: none;
}
.man-black-img {
  position: relative;
  top: -100px;
  width: 40%;
}
.ul-man-k {
  list-style-type: none;
  margin: 0;
  padding: 0 !important;
}
.ul-man-k li {
  font-size: calc(9px + (22 - 9) * ((100vw - 320px) / (1280 - 320)));
  letter-spacing: 1px;
  font-weight: normal;
  padding-bottom: 8px;
  position: relative;
  padding-left: 50px;
}
.kap-li {
  width: 40px;
  position: absolute;
  top: 3px;
  left: 0;
}
.h3-title-wb.h3-sostav {
  margin-top: 0px;
}
.bg-wave-sec-4 {
  position: absolute;
  bottom: -600px;
  left: -300px;
  z-index: 100;
  opacity: 0.6;
}
.section-5 {
  background: linear-gradient(#000, #017F8E, #000);
  position: relative;
  top: -50px;
}
.sostav-block {  
  max-width: 1280px;
  margin: 0 auto;
}
.kaplya-center {
  width: 80%;
  padding-top: 50px;
}
.act-col {
  padding-left: 60px;
  padding-right: 0px;
  position: relative;
  z-index: 1000;
}
.prev-col {
  padding-left: 40px;
  padding-right: 20px;
  position: relative;
  z-index: 1000;
}
.big-k-col {
  text-align: center;
}
.sostav-left {
  padding-left: calc(20px + (50 - 20) * ((100vw - 320px) / (1280 - 320)));
}
.sostav-right {
  
}
.sostav-ul-right, .sostav-ul-left {
  list-style-type: none;
  margin: 0;
  padding: 0 !important;
  z-index: 1000;
  position: relative;
}
.sostav-ul-left li, .sostav-ul-right li {
  font-size: 16px;  
  position: relative;
  cursor: pointer;
  // text-decoration: underline;
}
.sostav-ul-left li {
  padding-left: 50px;
  margin-bottom: 10px;
  padding-top: 5px;
}
.sostav-ul-right li {
  padding-left: 120px;
}
.kap-sostav {
  width: 40px;
  position: absolute;
  top: 1px;
  left: 0;
}
.img-sostav {
  max-width: 500px;
  width: 100%;
}
.close-btn {
  position: absolute;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  border: 1px solid #fff;
  padding: 1px 8px;
  border-radius: 50% !important;
  top: 5px;
  left: 5px;
  cursor: pointer;
}
.privacy-text.me-privacy {
  color: #fff !important;
}
.privacy-text.me-privacy a {
  color: #00e1ff !important;
}
.yagoda {
  height: 90px;
  display: flex;
}
.apelsin {
  height: 90px;
  display: flex;
}
.poroshok {
  height: 90px;
  display: flex;
}
.paper {
  height: 95px;
  display: flex;
}
.yagoda span, .apelsin span, .poroshok span, .paper span {
  align-self: center;
}
.yagoda img {
  width: 100px;
  position: absolute;
  top: 15px;
  left: 0;
}
.apelsin img {
  width: 100px;
  position: absolute;
  top: 12px;
  left: 0;
}
.poroshok img {
  width: 100px;
  position: absolute;
  top: 20px;
  left: 0;
}
.paper img {
  width: 100px;
  position: absolute;
  top: 1px;
  left: 0;
}
.bg-5-btm-r {
  width: calc(150px + (250 - 20) * ((100vw - 320px) / (1280 - 320)));
  position: absolute;
  bottom: 80px;
  right: 150px;
}
.btn-buy {
  width: 350px;
  z-index: 500;
  position: relative;
}
.section-7-1 {
  background: linear-gradient(#000, #017F8E, #000);
  position: relative;
}
.section-7-2 {
  background: linear-gradient(#000, #017F8E);
  position: relative;
  padding-top: 30px;
}
.h4-title {
  font-size: calc(10px + (24 - 10) * ((100vw - 320px) / (1280 - 320)));
  max-width: 800px;
  margin: 0 auto;
  margin-top: 20px;
  position: relative;
  z-index: 500;
}
.req-p {
  max-width: 800px;
  margin: 0 auto;
  padding-bottom: 40px;
  margin-bottom: 0 !important;
  position: relative;
  z-index: 500;
}
.ul-lr {
  max-width: 800px;
  margin: 0 auto;
  padding-bottom: 40px;
  margin-bottom: 0 !important;
  margin-top: 40px;
  font-size: calc(9px + (22 - 10) * ((100vw - 320px) / (1280 - 320)));
  text-transform: uppercase;
}
.ul-lr li {
  margin-bottom: 10px;
}
.cert-block {
  background-color: rgba($color: #fff, $alpha: 0.8);
  border-radius: 50px;
  max-width: 1000px;
  margin: 0 auto;
  margin-bottom: 40px;
  margin-top: 40px;
  color: #333;
  padding: 20px;
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  position: relative;
  z-index: 500;
}
.cert-col {
  display: flex;
  justify-content: center;
  align-self: center;
}
.img-col.dermatest {
  width: 80%;
}
.img-col.aisc {
  width: 50%;
}
.img-col.frezenius {
  width: 60%;
}
.bg-7-wave-r {
  position: absolute;
  right: 0;
  bottom: 20%;
  width: calc(50px + (80 - 20) * ((100vw - 320px) / (1280 - 320)));
}
.bg-7-wave-l {
  position: absolute;
  left: 0;
  bottom: 20%;
  width: calc(40px + (60 - 20) * ((100vw - 320px) / (1280 - 320)));
}
.bg-wave-btm {
  position: absolute;
  bottom: -900px;
  left: -300px;
  z-index: 100;
  opacity: 0.6;
}
.bg-wave-form {
  position: absolute;
  bottom: -900px;
  left: -300px;
  z-index: 100;
  opacity: 0.6;
}
.section-8 {
  background: linear-gradient(#017F8E, #000);
  position: relative;
}
.me-carousel {
  position: relative;
  z-index: 500;
  height: 400px !important;
}
.me-carousel .v-carousel__item {
  height: 400px !important;
}
.me-carousel .theme--light.v-sheet {
  background-color: rgba($color: #017F8E, $alpha: 0);
}
.ul-otzivi {
  max-width: 600px;
  margin: 0 auto;
  padding-bottom: 40px;
  margin-bottom: 0 !important;
  margin-top: 10px;  
  list-style-type: none;
  padding: 0 !important;
  z-index: 1000;
  position: relative;
}
.ul-otzivi li {
  font-size: calc(12px + (20 - 12) * ((100vw - 320px) / (1280 - 320)));  
  text-transform: uppercase;
  margin-bottom: 10px;
  position: relative;
  padding-left: 45px;
}
.check-li {
  width: 30px;
  position: absolute;
  left: 0;
  top: -5px;
}
.section-9 {
  background: linear-gradient(to top, #017F8E, #000);
  position: relative;
}
.section-10 {
  background: linear-gradient(#017F8E, #000);
  position: relative;
}
.form-name .v-icon, .input-phone .v-icon, .form-name .v-label, .input-phone .v-label {
  color: #fff;
}
.dialog-prospect, .dialog-sostav {
  z-index: 10000;
}
.me-footer {
  background-color: #000 !important;
  border-color: #000 !important;
  color: #fff !important;
}
.h2-form {
  font-size: calc(9px + (24 - 10) * ((100vw - 320px) / (1280 - 320)));  
  text-transform: uppercase;
  padding-top: 30px;
  padding-bottom: 30px;
  text-shadow: 1px 1px 2px #00e4ff, 0 0 1em #00e7ff, 0 0 0.2em #00c3ff;
  font-weight: normal;
}
.h3-form {
  font-size: calc(10px + (22 - 10) * ((100vw - 320px) / (1280 - 320)));  
  text-transform: uppercase;
  margin-bottom: 40px;
  // font-family: "Evolventa-Bold";
  font-weight: normal;
}
@media (min-width: 1400px) {
  .img-buy, .img-more, .img-send {
    width: 300px;
  }
  .h1-main{
    font-size: 40px;
  }
  .h2-title-main {
    font-size: 24px;
  }
  .h2-title {
    font-size: 28px;
  }
  .h2-title-wb {
    font-size: 28px;
  }
  .h3-title {
    font-size: 24px;
  }
  .h3-title-wb {
    font-size: 24px;
  }
  .h3-blue {
    font-size: 22px;
  }
  .p-man {
    font-size: 20px;
  }
  .ul-man {
    font-size: 22px;
  }
  .h4-title {
    font-size: 24px;
  }
  .ul-man-k li {
    font-size: 22px;
  }
  .ul-lr {
    font-size: 22px;
  }
  .ul-otzivi li {
    font-size: 20px;
  }
  .h2-form {
    font-size: 24px;
  }
  .h3-form {
    font-size: 22px;
  }
}
@media (max-width: 1400px) {
  .img-buy, .img-more, .img-send {
    max-width: 300px;
  }
  .bg-wave-sec-4 {
    bottom: -800px;
  }
  .man-black-img {
    width: 45%;
  }
  .section-5 {
    top: -20px;
  }
}
@media (max-width: 1200px) {
  .man-black-img {
    width: 50%;
    top: 0;
  }
  .section-5 {
    top: -10px;
  }
}
@media (max-width: 1000px) {
  .bg-main-wave-1 {
    top: -45%;
  }
  .bg-5-btm-r {
    bottom: 100px;
    right: 10px;
  }
  .man-black-img {
    width: 50%;
    top: 0;
  }
  .section-5 {
    top: 0px;
  }
}
@media (max-width: 960px) {  
  .section-2 {
    padding-top: 20px;
  }
}
@media (max-width: 800px) {
  .bg-main-wave-1 {
    top: -70%;
  }
  section {
    padding-right: 5px;
    padding-left: 5px;
  }
  .section-2 {
    padding-left: 0;
  }
  .me-carousel {
    height: 370px !important;
  }  
  .br-mobile {
    display: none;
  }
  .man-black-img {
    width: 60%;
  }
  .bg-wave-sec-4 {
    bottom: -550px;
    left: -400px;
    width: 1600px;
  }
}
@media (max-width: 600px) {
  .bg-main-wave-1 {
    top: -30%;
  }
  .ul-man li {
    padding-bottom: 5px;
    margin-left: 10px;
  }
  .h3-blue {
    text-align: center;
  }
  // .col-box, .col-black-man {
  //   display: none;
  // }
  // .boxer-img-mobile, .man-black-mobile {
  //   display: block;
  //   position: absolute;
  //   width: 300px;
  //   z-index: 100;
  //   opacity: 0.25;
  // }
  .h2-title-wb.top-h3-sec-2, .h3-title-wb.btm-h3-sec-2 {
    text-align: center;
  }
  .ul-man {
    padding-left: 0 !important;
  }
  .ul-man-k li {
    padding-left: 25px;
  }
  .big-k-col {
    display: none;
  }
  .kap-li {
    width: 20px;
    top: 0px;
  }
  .act-col, .prev-col {
    padding-left: 20px;
    padding-right: 20px;
  }
  .img-col.dermatest, .img-col.aisc, .img-col.frezenius {
    width: 100%;
  }
  .me-carousel {
    height: 350px !important;
  }
  .me-carousel .v-carousel__item {
    height: 370px !important;
  }
  .me-carousel .img-corusel{
    padding: 0 20px;
  }
  .ul-otzivi {
    margin-top: 0;
    max-width: 450px;
  }
  .ul-otzivi li {
    padding-left: 35px;
  }
  .check-li {
    width: 25px;
  }
  .section-7-1 {
    background: linear-gradient(#000, #017F8E);
  }
  .section-7-2 {
    background: linear-gradient(#017F8E, #017F8E);
  }
  .man-black-img {
    width: 80%;
  }
}
@media (max-width: 520px) {
  .me-carousel {
    height: 320px !important;
  }
  .me-carousel .v-carousel__item {
    height: 350px !important;
  }
}
@media (max-width: 480px) {
  .sec-2-txt {
    // padding-top: 40px;
  }
  .br-mob {
    display: block;
  }
  .h2-title-wb.top-h3-sec-2 {
    padding-bottom: 20px;
  }
  .p-man {
    margin-bottom: 0 !important;
  }
  .h3-title-wb.btm-h3-sec-2 {
    padding-top: 20px;
    padding-bottom: 0;
  }
  .h3-blue {
    padding-bottom: 20px;
  }  
  .bg-wave-sec-4 {
    width: 650px;
    bottom: -200px;
    left: -130px;
  }
  // .kap-li {
  //   top: 0;
  //   width: 35px;
  // }
  .h3-title-wb.h3-sostav {
    font-size: 16px;
    margin-top: 10px;
    padding-top: 0;
  }
  .act-col {
    padding-top: 0;
  }
  .sos-p {
    font-size: 11px;
    line-height: 150% !important;
  }
  .p-18 {
    font-size: 14px;
  }
  .ul-col {
    font-size: 13px;
  }
  .sostav-ul-left {
    padding-left: 20px;
  }
  .sostav-ul-left li, .sostav-ul-right li {
    font-size: 14px;
  }
  .sostav-ul-right li {
    font-size: 11px;
    padding-left: 80px;
  }
  .sostav-ul-left li {
    padding-left: 40px;
    margin-bottom: 5px;
  }
  .kap-sostav {
    width: 30px;
  }
  .yagoda {
    height: 60px;
  }
  .yagoda img {
    width: 60px;
    top: 10px;
    left: 10px;
  }
  .apelsin {
    height: 65px;
  }
  .apelsin img {
    width: 65px;
    top: 10px;
    left: 5px;
  }
  .poroshok {
    height: 70px;
  }
  .paper {
    height: 60px;
  }
  .poroshok img {
    width: 70px;
    top: 15px;
  }
  .paper img {
    width: 60px;
    top: 5px;
    left: 5px;
  }
  .section-7-2 {
    padding-top: 30px;
  }
  .bg-7-wave-l {
    bottom: 35%;
  }
  .req-p {
    padding-bottom: 10px;
    font-size: 7px;
    line-height: 130% !important;
  }
  .ul-lr {
    padding-bottom: 0;
    padding-left: 15px !important;
    margin-top: 20px;
  }
  .ul-lr li {
    margin-bottom: 0px;
  }
  .h2-title-wb.lr-proizv {
    font-size: 13px;
    padding-bottom: 10px;
  }
  .h3-title-wb.lr-more {
    font-size: 12px;
    padding-bottom: 0;
  }
  .cert-col {
    font-size: 7px;
  }
  .cert-block {
    border-radius: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
    padding: 10px;
  }
  .cert-col.cert-img {
    padding: 0 0 0 10px;
  }
  .img-col.aisc {
    width: 60%;
  }
  .img-col.frezenius {
    width: 70%;
  }
  .h2-title-wb.lr-otziv {
    padding-bottom: 0;
  }  
  .bg-wave-btm {
    bottom: -300px;
    left: -150px;
    width: 650px;
  }
  .me-carousel {
    height: 300px !important;
  }
  .me-carousel .v-carousel__item {
    height: 320px !important;
  }
  .ul-otzivi {
    max-width: 300px;
  }
  .ul-otzivi li {
    padding-left: 30px;
  }
  .check-li {
    width: 20px;
  }
  .h2-form {
    padding-top: 10px;
  }
  .h2-form {
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .h3-form {
    margin-bottom: 10px;
  }
  .h3-title-wb.man-alfa {
    font-size: 13px;
    padding-bottom: 0;
  }
  .h3-title-wb.man-end {
    font-size: 13px;
  }
  .img-send {
    margin-top: 20px;
  }
  .bg-wave-form {
    bottom: -230px;
    left: -100px;
    width: 600px;
  }
  .man-black-img {
    width: 100%;
  }
}
@media (max-width: 450px) {
  .me-carousel {
    height: 290px !important;
  }
}
@media (max-width: 420px) {
  .me-carousel {
    height: 270px !important;
  }
  .me-carousel .v-carousel__item {
    height: 290px !important;
  }
}
@media (max-width: 400px) {
  .me-carousel {
    height: 250px !important;
  }
  .me-carousel .v-carousel__item {
    height: 270px !important;
  }
}
@media (max-width: 360px) {
  .me-carousel {
    height: 240px !important;
  }
  .me-carousel .v-carousel__item {
    height: 270px !important;
  }
}
</style>